<template>
  <div>
    <!-- <b-row class="py-1">
      <b-col lg="12" class="text-right">
        <b-button variant="primary" @click="openModalCreateCustomerTicket({})">
          <feather-icon
            icon="PlusIcon"
            size="15"
            class="mr-50 text-white"
          /><strong>NEW TICKET</strong>
        </b-button>

        <b-button
          variant="info"
          class="ml-1"
          :to="{
            name: redirectTicket(),
          }"
        >
          <feather-icon icon="HomeIcon" size="15" class="mr-50 text-white" />
          <strong> MAIN TICKETS GRID</strong>
        </b-button>
      </b-col>
    </b-row> -->

    <modal-create-customer-ticket
      v-if="showModalCreateCustomerTicket"
      :customer-id="this.$route.params.idClient"
      :customer-ticket="customerTicket"
      @hidden="showModalCreateCustomerTicket = false"
      @saved="refresh"
    />

    <grid-customer-tickets
      ref="routeView"
      :customer-account="this.$route.params.idClient"
    />
  </div>
</template>

<script>
import ClientDashboardService from "@/views/paragon/views/clients/dashboard/services/dashboard.service";
import { mapGetters } from "vuex";
import ModalCreateCustomerTicket from "@/views/commons/components/customer-tickets/components/modal/ModalCreateCustomerTicket.vue";
import GridCustomerTickets from "@/views/commons/components/customer-tickets/views/GridCustomerTickets.vue";

export default {
  components: {
    ModalCreateCustomerTicket,
    GridCustomerTickets,
  },
  data() {
    return {
      ticketArray: [],
      customerTicket: null,
      showModalCreateCustomerTicket: false,
      moduleIdToRouteMap: {
        0: "customer-tickets-ceo",
        2: "crm-customer-tickets",
        3: "business-customer-tickets",
        4: "administration-customer-tickets",
        5: "debt-solution-customer-tickets",
        6: "credit-experts-customer-tickets",
        7: "boost-credit-customer-tickets",
        8: "tax-research-customer-tickets",
        9: "correspondence-customer-tickets",
        10: "court-info-customer-tickets",
        11: "analyst-department-customer-tickets",
        12: "paragon-customer-tickets",
        14: "bookeeping-customer-tickets",
        15: "social-network-customer-tickets",
        16: "management-customer-tickets",
        17: "rrhh-customer-tickets",
        18: "quality-customer-tickets",
        19: "logistic-customer-tickets",
        20: "connection-customer-tickets",
        22: "customer-service-customer-tickets",
        23: "financial-customer-tickets",
        25: "specialist-digital-customer-tickets",
        26: "sales-customer-tickets",
        27: "creative-customer-tickets",
        28: "specialists-analyst-customer-tickets",
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isCeo() {
      return this.currentUser.role_id === 1;
    },
  },
  mounted() {
    this.listTicketPerCustomer();
  },
  methods: {
    redirectTicket() {
      if (!this.isCeo) {
        return this.moduleIdToRouteMap[this.currentUser.main_module];
      }
      return this.moduleIdToRouteMap[0];
    },
    async listTicketPerCustomer() {
      try {
        this.addPreloader();

        const obj = {
          client_account: this.$route.params.idClient,
        };
        const res = await ClientDashboardService.listTicketPerCustomer(obj);
        if (res.status === 200) {
          this.ticketArray = res.data;
          this.removePreloader();
        }
      } catch (error) {
        console.error(error);
        this.showWarningSwal();
        this.removePreloader();
      }
    },
    openModalCreateCustomerTicket(item) {
      this.customerTicket = { ...item };
      this.showModalCreateCustomerTicket = true;
    },
    refresh() {
      this.showModalCreateCustomerTicket = false;
      this.listTicketPerCustomer();
    },
  },
};
</script>
